import { CircularProgress } from "@mui/material";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import ConfirmationPopup from "../../components/GeneralComponents/ConfirmationPopup";
import CustomDrawer from "../../components/GeneralComponents/CustomDrawer";
import CustomMUITable from "../../components/GeneralComponents/CustomMUITable/CustomMUITable";
import { clientBaseUrl, s3baseUrl } from "../../config/config";
import ComponentPopup from "../../components/GeneralComponents/ComponentPopup";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import {
  clients_list_api,
  delete_client_api,
  generate_client_token_api,
} from "../../DAL/Clients/Clients";
import AddOrUpdateClient from "../../components/Clients/AddOrUpdateClient";
import ChangePassword from "../../components/Clients/ChangePassword";
import { CURRENCIES } from "../../utils/constant";

export default function ClientsList() {
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const [isOpenDrawer, setIsOpenDrawer] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [formType, setFormType] = useState("ADD");
  const [clientsList, setClientsList] = useState([]);
  const [openDelete, setOpenDelete] = useState(false);
  const [selectedObject, setSelectedObject] = useState({});
  const [openPassword, setOpenPassword] = useState(false);

  const handleOpenPasswordPopup = (data) => {
    setSelectedObject(data);
    setOpenPassword(true);
  };

  const handleClosePasswordPopup = () => {
    setOpenPassword(false);
    setSelectedObject({});
  };

  const handleOpenDrawer = () => {
    setIsOpenDrawer(true);
  };
  const handleCloseDrawer = () => {
    setIsOpenDrawer(false);
    setSelectedObject({});
    setFormType("ADD");
  };

  const get_clients_list = async () => {
    const result = await clients_list_api();
    if (result.code === 200) {
      const data = result.clients_list.map((client) => {
        let currency_label = "";
        let find_currency = CURRENCIES.find((c) => c.value === client.currency);
        if (find_currency) {
          currency_label = find_currency.label;
        }

        return {
          ...client,
          table_avatar: {
            src: s3baseUrl + client.profile_image,
            alt: client.name,
          },
          associate_name: client.associate?.name,
          created_at: moment(client.createdAt).format("DD-MM-YYYY hh:mm A"),
          currency_label,
        };
      });
      setClientsList(data);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const TABLE_HEAD = [
    { id: "action", label: "Action", type: "action" },
    { id: "number", label: "#", type: "number" },
    {
      id: "table_avatar",
      label: "Profile",
      type: "thumbnail",
      className: "reduce-cell-padding",
    },
    { id: "name", label: "Name" },
    { id: "nickname", label: "Nickname" },
    { id: "email", label: "Email" },
    { id: "phone", label: "Phone" },
    { id: "associate_name", label: "Affiliate Name" },
    { id: "address", label: "Address" },
    { id: "currency_label", label: "Currency" },
    { id: "status", label: "Status", type: "row_status" },
    { id: "created_at", label: "Created At" },
  ];

  const handleEdit = (data) => {
    setSelectedObject(data);
    setFormType("EDIT");
    handleOpenDrawer();
  };

  const handleAgreeDelete = (data) => {
    setSelectedObject(data);
    setOpenDelete(true);
  };

  const handleFeesDetails = (data) => {
    navigate(`/clients/fees-management/${data._id}`);
  };

  const generate_token = async (row) => {
    const result = await generate_client_token_api(row._id);
    if (result.code === 200) {
      window.open(`${clientBaseUrl}login-by-admin/${result.token}`);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };

  const handlePersonalNotes = (data) => {
    navigate(`/clients/personal-notes/${data._id}`);
  };

  const handleUserDevices = (data) => {
    navigate(`/clients/user-devices/${data._id}`);
  };

  const handleToDoList = (data) => {
    navigate(`/clients/to-do-lists/${data._id}`);
  };

  const MENU_OPTIONS = [
    {
      label: "Edit",
      icon: "akar-icons:edit",
      handleClick: handleEdit,
    },
    {
      label: "Login As Client",
      icon: "majesticons:login-line",
      handleClick: generate_token,
    },
    {
      label: "Change Password",
      icon: "material-symbols:key-outline",
      handleClick: handleOpenPasswordPopup,
    },
    {
      label: "Fees Details",
      icon: "pepicons-pencil:leave",
      handleClick: handleFeesDetails,
    },
    {
      label: "Personal Notes",
      icon: "codicon:note",
      handleClick: handlePersonalNotes,
    },
    {
      label: "User Devices",
      icon: "ph:device-mobile-duotone",
      handleClick: handleUserDevices,
    },
    {
      label: "To Do List",
      icon: "ic:baseline-list",
      handleClick: handleToDoList,
    },
    {
      label: "Delete",
      icon: "ant-design:delete-twotone",
      handleClick: handleAgreeDelete,
    },
  ];

  const handleDelete = async () => {
    setOpenDelete(false);
    const result = await delete_client_api(selectedObject._id);
    if (result.code === 200) {
      const data = clientsList.filter(
        (client) => client._id !== selectedObject._id
      );
      setClientsList(data);
      enqueueSnackbar(result.message, { variant: "success" });
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };

  useEffect(() => {
    get_clients_list();
  }, []);

  if (isLoading) {
    return (
      <div className="circular-progress">
        <CircularProgress color="primary" />
      </div>
    );
  }

  return (
    <>
      <div className="container-fluid mt-3">
        <div className="row">
          <div className="col-12">
            <div className="theme-card p-3">
              <div className="d-flex flex-column flex-md-row justify-content-between light-border-bottom">
                <div className="mb-2 mui-table-heading">
                  <h2>Clients List</h2>
                </div>
                <div className="add--button mb-3 text-end">
                  <button onClick={handleOpenDrawer}>Add Client</button>
                </div>
              </div>
              <CustomMUITable
                TABLE_HEAD={TABLE_HEAD}
                data={clientsList}
                MENU_OPTIONS={MENU_OPTIONS}
              />
            </div>
          </div>
        </div>
      </div>
      <CustomDrawer
        isOpenDrawer={isOpenDrawer}
        onOpenDrawer={handleOpenDrawer}
        onCloseDrawer={handleCloseDrawer}
        pageTitle={`${formType === "ADD" ? "Add" : "Update"} Client`}
        componentToPassDown={
          <AddOrUpdateClient
            formType={formType}
            onCloseDrawer={handleCloseDrawer}
            clientsList={clientsList}
            setClientsList={setClientsList}
            selectedObject={selectedObject}
          />
        }
      />

      <ConfirmationPopup
        openDelete={openDelete}
        setOpenDelete={setOpenDelete}
        title={"Are you sure you want to delete this Client?"}
        handleAgree={handleDelete}
      />

      <ComponentPopup
        openPopup={openPassword}
        setOpenPopup={setOpenPassword}
        title="Change Password"
        componentToPassDown={
          <ChangePassword
            handleClose={handleClosePasswordPopup}
            selectedObject={selectedObject}
          />
        }
      />
    </>
  );
}
