import React, { useState } from "react";
import { logo } from "../../../assets";
import MeetingView from "./MeetingView";
import { useClassRoom } from "../../../Hooks/ClassRoom";

const DraggableBox = () => {
  const [boxType, setBoxType] = useState("BOX"); // Types: BOX, CIRCLE, FULL
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const { callUser } = useClassRoom();

  const handleMouseDown = (e) => {
    const offsetX = e.clientX - position.x;
    const offsetY = e.clientY - position.y;

    const handleMouseMove = (e) => {
      setPosition({
        x: e.clientX - offsetX,
        y: e.clientY - offsetY,
      });
    };

    const handleMouseUp = () => {
      window.removeEventListener("mousemove", handleMouseMove);
      window.removeEventListener("mouseup", handleMouseUp);
    };

    window.addEventListener("mousemove", handleMouseMove);
    window.addEventListener("mouseup", handleMouseUp);
  };

  const isDraggable = boxType === "BOX";
  const isCircle = boxType === "CIRCLE";
  const isFull = boxType === "FULL";

  return (
    <div
      className={`draggable-container ${
        isDraggable
          ? "draggable-box"
          : isCircle
          ? "circle-box"
          : "full-call-box"
      }`}
      style={{
        left: isFull ? 0 : position.x,
        top: isFull ? 0 : position.y,
        position: isFull ? "fixed" : "absolute",
      }}
      onMouseDown={isDraggable ? handleMouseDown : undefined}
      onClick={isCircle ? () => setBoxType("BOX") : undefined}
    >
      {callUser ? (
        <MeetingView setBoxType={setBoxType} boxType={boxType} />
      ) : (
        <div className="image-box">
          <img src={logo} alt="Logo" />
        </div>
      )}
    </div>
  );
};

export default DraggableBox;
